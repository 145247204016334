import { QtmProgressCircle } from '@qtm/react';
import { useGetTokenUserQuery } from 'api/tokenEndPoints';
import { useGetCurrentUserQuery } from 'api/UserEndPoints';
import isBoolean from 'lodash/isBoolean';
import PrivacyPreferenceCenter from 'modules/Landing/PrivacyPreferenceCenter';
import Routing from 'Routing';
import { useGetSkyOlightToken } from 'shared/authentication/tokenHook';
import LogAuthentication from 'shared/log/LogAuthenticattionHook';

const Landing = () => {
  const token = useGetSkyOlightToken();

  const { data: dataToken, isLoading: isLoadingByToken } = useGetTokenUserQuery(token as string, {
    skip: !token,
  });

  const { data, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: !!token,
  });

  if (isLoading || isLoadingByToken) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <QtmProgressCircle classes="" track size="xlarge" />
      </div>
    );
  }

  if (!data && !dataToken) {
    return null;
  }

  const hasChoosen = isBoolean(data?.cookieAccepted) || isBoolean(dataToken?.cookieAccepted);

  if (hasChoosen) {
    return (
      <LogAuthentication>
        <Routing />
      </LogAuthentication>
    );
  }

  return <PrivacyPreferenceCenter />;
};

export default Landing;
