import { api } from 'api/api';
import { User } from 'shared/model/user.model';

export const extendedTokenApi = api
  .enhanceEndpoints({
    addTagTypes: ['tokenUser'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getTokenUser: builder.query<User, string>({
        query: token => ({
          url: `/user/${token}`,
          method: 'GET',
        }),
        providesTags: ['tokenUser'],
      }),
    }),
  });

export const { useGetTokenUserQuery } = extendedTokenApi;
